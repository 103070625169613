import React from 'react';
import ImageRevealer from "./components/ImageRevealer";

function App() {
  return (
    <ImageRevealer topImage={`/images/top.jpg`} bottomImage="/images/bottom.jpg" />
  );
}

export default App;
