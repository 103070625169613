import React,{useRef,useState,useEffect} from "react";

const ImageRevealer = ({topImage,bottomImage}) => {

    const topImageShell = useRef(null);
    const mainShell = useRef(null);
    const topImageRef = useRef(null);
    const sliderRef = useRef(null);

    const [height,setHeight] = useState('auto');
    const [width,setWidth] = useState('75vw');
    const [loaded,setLoaded] = useState(false);
    const [holdingSlider,setHoldingSlider] = useState(false);
    const [sliderX,setSliderX] = useState('98%');

    useEffect(() => {

        window.addEventListener('mousedown',onWindowMouseDown);
        window.addEventListener('mouseup',onWindowMouseUp);

        return () => {
            window.removeEventListener('mousedown',onWindowMouseDown);
            window.removeEventListener('mouseup',onWindowMouseUp);

        }
    },[]);

    const onWindowMouseUp = e => {

        setHoldingSlider(false);
        
    }

    const onWindowMouseDown = e => {
        
        const slider = sliderRef.current;
        
        if(e.target === slider || e.target.parentNode === slider)
        {
            setHoldingSlider(true);
        }
    }

    const onTopImageLoad = e => {
        
        const width = topImageRef.current.width;
        const height = topImageRef.current.height;

        setHeight(height + 'px');
        setWidth(width + 'px');
    }

    const onMouseMove = e => {

        const slider = sliderRef.current;
        const shellWidth = mainShell.current.offsetWidth;
        const shellLeft = mainShell.current.offsetLeft;

        let mouseX = e.clientX - shellLeft;

        if(holdingSlider)
        {
            if(mouseX < 0) mouseX = 0;
            if(mouseX > shellWidth) mouseX = shellWidth;

            setSliderX(mouseX);
        }
    }

    return (<div 
                className={`ImageRevealer${loaded ? ' ImageRevealer--loaded' : ''}`} 
                style={{
                    height : height,
                    width: width
                }}
                onMouseMove={onMouseMove}
                ref={mainShell}>
        
        <img 
            src={process.env.PUBLIC_URL + bottomImage} 
            className="ImageRevealer__image ImageRevealer__image--bottom" alt="" />

        <div 
            className="ImageRevealer__image-shell ImageRevealer__image-shell--top"
            ref={topImageShell}
            style={{
                width : sliderX
            }}
        >
            <img src={process.env.PUBLIC_URL + topImage}
                style={{
                    width : width
                }} 
                ref={topImageRef}
                onLoad={onTopImageLoad}
                className="ImageRevealer__image ImageRevealer__image--top" alt="" />
        </div>

        
        <div 
            className="ImageRevealer__slider"
            ref={sliderRef}
            style={{
                left : sliderX
            }}
        >
            <div className="ImageRevealer__slider-handles"></div>
        </div>
    </div>);
}

export default ImageRevealer;